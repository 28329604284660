// @ts-ignore
import React, { useState, useEffect } from "react";
import { Container, Col, Row, Image } from "react-bootstrap";

import SEO from "../../components/shared/seo";
import {
  FadeUpBox,
  FadeIn,
  ImageInRight,
} from "../../components/shared/transitions";
import { OverlayFillGradient } from "../../components/shared/overlay";
import { IoIosQuote } from "react-icons/io";
import { useStaticQuery, graphql } from "gatsby";
import { CaseStudyNav } from "../../components/shared/case-study-nav";
import { Controller, Scene } from "react-scrollmagic";
import LayoutCaseStudy from "../../components/layouts/layout-case-study";
import { ParallaxBanner } from "react-scroll-parallax";
import { QuoteBlock } from "../../components/shared/quote-block";
import { Helmet } from "react-helmet";

function MurdochPage({ data }) {
  const [animate, setAnimate] = useState(false);

  const murdochImages = useStaticQuery(graphql`
    query {
      murdochBanner: file(relativePath: { eq: "murdoch-banner.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2000, quality: 70) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      murdochLogoSm: file(relativePath: { eq: "murdoch-logo-sm.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 400, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      prevWorkImage: file(relativePath: { eq: "card-vas.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1000, quality: 70) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      nextWorkImage: file(relativePath: { eq: "wesfarmers_casestudy.png" }) {
        childImageSharp {
          fluid(maxWidth: 1000, quality: 70) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      sitefinityLogo: file(relativePath: { eq: "sitefinity-logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 400, quality: 100) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      azureLogo: file(relativePath: { eq: "azure-logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 400, quality: 100) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `);

  useEffect(() => {
    const timer = setTimeout(() => {
      setAnimate(true);
    }, 500);

    return () => {
      clearTimeout(timer);
    };
  });

  return (
    <>
      <Helmet>
        <script type="application/ld+json">
          {`
          {
            "@context": "http://schema.org",
            "@type": "Organization",
            "name": "Diviv Group",
            "description": ".NET applications, Progress Sitefinity CMS, Drupal, GovCMS, Sitecore Experience Platform, Microsoft Sharepoint, Dynamics CRM and Azure Hosting support in Perth Australia. Coupled with our creative and user experience team we are able to provide expert consultation and resources on a wide range of digital development projects across many industries.",
            "url": "https://www.diviv.com.au",
            "image": "https://www.diviv.com.au/icons/icon-96x96.png",
            "address": {
              "@type": "PostalAddress",
              "streetAddress": "165 / 15 Aberdeen St",
              "addressLocality": "Perth",
              "addressRegion": "WA",
              "postalCode": "6000",
              "addressCountry": "AU"
            },
            "aggregateRating": {
              "@type": "AggregateRating",
              "ratingValue": "5",
              "ratingCount": "1"
            },
            "review": {
              "@type": "Review",
              "author": "Simon Dutton",
              "datePublished": "2020-03-09",
              "reviewBody": "The team’s efforts to get us to a position of Go Live with our News Portal was exceptional. Your hard work and diligence has been pivotal to get us over the line on this one with very tight timeframes. Thanks guys.",
              "reviewRating": {
                "@type": "Rating",
                "bestRating": "5",
                "ratingValue": "5",
                "worstRating": "1"
              }
            }
          }
          `}
        </script>
      </Helmet>
      <SEO title="Murdoch University" />
      <LayoutCaseStudy>
        <div
          id="murdoch-university-content"
          className="page-layout"
          data-menu-class="inverted"
        >
          <Container>
            <Row>
              <Col sm={12} lg={8}>
                <div className="client-logo--lg mb-4">
                  <Image
                    src={murdochImages.murdochLogoSm.childImageSharp.fluid.src}
                  />
                </div>
                <FadeUpBox pose={animate ? "enter" : "exit"}>
                  <FadeIn>
                    <h6>Support, Development and Integration</h6>
                  </FadeIn>
                  <FadeIn>
                    <h2>Murdoch University</h2>
                  </FadeIn>
                  <FadeIn>
                    <p>
                      Diviv are the development and support partner for Murdoch
                      University. Murdoch trust us to manage their business
                      critical website infrastructure, website code base and
                      CMS, which is accessed 24 hours a day by students from all
                      around the world.
                    </p>
                  </FadeIn>
                </FadeUpBox>
              </Col>
            </Row>

            <Row className="" id="cms-section">
              <Col>
                <Controller>
                  <Scene
                    triggerElement="#cms-section"
                    reverse={false}
                    duration={100}
                  >
                    {(progress, event) => (
                      <FadeUpBox
                        pose={progress > 0 ? "enter" : "exit"}
                        delay={200}
                      >
                        <FadeIn>
                          <Row>
                            <Col
                              sm={12}
                              md={12}
                              className="cms-outer-container"
                            >
                              <Col
                                sm={12}
                                md={12}
                                lg={9}
                                className="cms-inner-container"
                              >
                                <div className="logoWrapper">
                                  <div>
                                    <Image
                                      src={
                                        murdochImages.azureLogo.childImageSharp
                                          .fluid.src
                                      }
                                    />
                                  </div>
                                </div>

                                <div className="textWrapper">
                                  <h4>Azure</h4>

                                  <p>
                                    Murdoch has strict change control practices
                                    and methodologies with a low tolerance to
                                    any downtime. Taking this into account,
                                    Diviv have designed a redundant solution
                                    utilising the latest Azure app hosting
                                    technology which gives Murdoch the ability
                                    to make changes to their production systems
                                    with zero downtime.
                                  </p>
                                  <p>
                                    We also provide ongoing maintenance and
                                    improvement work as required by the Murdoch
                                    Digital team.
                                  </p>
                                </div>
                              </Col>
                            </Col>
                          </Row>
                        </FadeIn>
                      </FadeUpBox>
                    )}
                  </Scene>
                </Controller>
              </Col>
            </Row>
          </Container>
        </div>

        <section className="quote-wrapper">
          <Container>
            <QuoteBlock
              author="Simon Dutton"
              authorPosition="Project Manager"
              quoteText="The team’s efforts to get us to a position of Go
                              Live with our News Portal was exceptional. Your
                              hard work and diligence has been pivotal to get us
                              over the line on this one with very tight
                              timeframes. Thanks guys."
              clientLogoUrl={
                murdochImages.murdochLogoSm.childImageSharp.fluid.src
              }
            />
          </Container>
        </section>

        <CaseStudyNav
          nextUrl="/our-work/wesfarmers"
          nextImage={murdochImages.nextWorkImage.childImageSharp.fluid.src}
          nextTitle="Wesfarmers"
        />
      </LayoutCaseStudy>
    </>
  );
}

export default MurdochPage;
